import React, { useEffect, useState } from 'react'
import Button1 from '../../../Button/Button1';
import ListOption from './ListOption';

import classes from "../CreateRule/SelectOutputs.module.css";

const SelectOutputs = ({ body, setBody, listItems, modalPageIndex, outputList, nextPage, isReminder }) => {

    //pass back selected outputs
    const [selectedOutputsInt, setSelectedOutputsInt] = useState([]);

    const HandeOnClick = () => {

        const tempArray = selectedOutputsInt

        const selectedOutputsAsString = tempArray;

        if (selectedOutputsAsString.length != 0) {
            setBody(prevBody => ({ ...prevBody, pin: selectedOutputsAsString }));
        }
        // isReminder == 1 ? nextPage(1) : nextPage(2);
        nextPage(1);
    }

    const HandleOnItemClicked = (item) => {
        const doesItemAlreadyExitInArray = selectedOutputsInt.find(Pin => Pin == item.Pin);

        if (doesItemAlreadyExitInArray) {
            const outputPin = listItems.find(Pin => Pin.Pin == doesItemAlreadyExitInArray);
            removeItem(outputPin)
        } else {
            const outputPin = listItems.find(Pin => Pin.pinName == item.pinName);
            addItem(outputPin)
        }
    }

    const addItem = (item) => {
        // Create a copy of the current array
        const newArray = [...selectedOutputsInt];
        // Push the new item to the copy
        newArray.push(item.Pin);

        // Set the state with the new array
        setSelectedOutputsInt(newArray);

    };

    const removeItem = (item) => {
        const newArray = [...selectedOutputsInt];
        const filterArray = newArray.filter((Pin, index) => Pin != item.Pin);

        // Set the state with the new array
        setSelectedOutputsInt(filterArray);

    }

    //filter out unwanted outputs
    const FilterArray = (listItems) => {
        // return listItems.filter(item => item.Pin != body.input);
        // return listItems.filter(item => item.pinName !== body.input && item.type !== "PULSE");

        return listItems
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "15px" }}>



            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                <h2>Which Switch do you want the automation on?</h2>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "20px", width: "100%" }}>
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", gap: "10px" }}>

                        {FilterArray(listItems).map((item, index) => {
                            return (
                                <Button1 key={index} onClick={() => HandleOnItemClicked(item)} style={{ backgroundColor: selectedOutputsInt.find(Pin => Pin == item.Pin) && "var(--Primary-Color)", color: selectedOutputsInt.find(Pin => Pin == item.Pin) && "white", border: selectedOutputsInt.find(Pin => Pin == item.Pin) && "2px solid lightblue" }}>
                                    {item.pinName}
                                </Button1>

                            )
                        })}
                    </ul >
                    {modalPageIndex == 0 ?
                        <button className={classes.nextButton} disabled={selectedOutputsInt.length == 0} onClick={HandeOnClick} >{">"}</button> :
                        <button className={classes.backButton} disabled={selectedOutputsInt.length == 0} onClick={HandeOnClick} >{"<"}</button>
                    }
                    {/* <div className={classes.nextButton} onClick={HandeOnClick} >{">"}</div>
                    <div className={classes.backButton} onClick={HandeOnClick} >{"<"}</div> */}
                </div>
            </div>

        </div >
    )
}

export default SelectOutputs