import React, { Suspense, useMemo, useState, useEffect } from 'react'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { useDispatch, useSelector } from 'react-redux';
import { Await, defer, useLoaderData } from 'react-router-dom';
import { getCompanyUserList, getUsersDevices } from '../../Api Methods/Api';
import DevicesGrid from '../../components/GridList/DevicesGrid';
import FilterUsersDeviceSearch from '../../components/PowerSearch/FilterUsersDeviceSearch';
import ActivityTableLayout from '../../components/Table/ActivityTableLayout';
import PortalUsersTableLayout from '../../components/Table/PortalUsersTableLayout';
import UserDevicesTableLayout from '../../components/Table/UserDevicesTableLayout';
import SearchBar from '../SearchView/Search/SearchBar';
import PortalActivityPage from '../PortalActivity/PortalActivityPage';
import classes from "./UserDevicesPage.module.css"
import DataManager from '../../hooks/DataManager';

const UserDevicesPage = () => {

  // ---------------------------- loader data ---------------------------------
  const { userDevices } = useLoaderData();
  useEffect(() => {
    if (userDevices.isError) {
      return userDevices.message;
    }
  }, [userDevices])

  const dataManager = new DataManager()

  const [UserDevices, setUserDevices] = useState([]);

  const client = useSelector(state => state.auth.client);
  const token = useSelector(state => state.auth.token);
  const ClickedUserID = useSelector(state => state.auth.ClickedUserID);
  const companyName = useSelector(state => state.auth.companyName);

  const pageFetchedIndex = dataManager.getItem('pageIndex')

  const apiData = {
    token,
    client
  }

  //------------------------------- responsible for updating the User's Devices Page data every 1 mintue ------------------------------------------------------
  const dispatch = useDispatch();
  useEffect(() => {

    // set initial portalActivityEvents on mount
    setUserDevices(userDevices);

    const HandleUsersDevices = async () => {
      const data = await getUsersDevices(apiData, ClickedUserID, companyName, dispatch)
      setUserDevices(data)
    }

    // Set up a timer to fetch data every 1 minute
    const timer1 = setInterval(() => {

      HandleUsersDevices();
    }, 60000);
    return () => clearInterval(timer1);
  }, []);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------

  const [searchQuery, setSearchQuery] = useState("");

  const [isloading, setIsLoading] = useState(false)


  const handleFilterLogic = async (data, searchQuery, selectedCategories) => {
    // Filter data based on search query and category

    const Data = await data;
    const filteredResults = Data.filter(result => {



      // Check if the result matches the search query
      const matchesSearchQuery = result.name && result.name.toLowerCase().includes(searchQuery.toLowerCase()) || result.tag.toLowerCase().includes(searchQuery.toLowerCase()) || result.key.toLowerCase().includes(searchQuery.toLowerCase());

      // // Check if the result matches the selected category
      // let matchesCategory = true;
      // if (selectedCategories.length > 0) {
      //     //----
      //     if (selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.favorited;
      //     }
      //     if (selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = !result.favorited;
      //     }
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.favorited || !result.favorited;
      //     }
      //     //----
      //     if (selectedCategories.includes('Notifications On')) {
      //         matchesCategory = isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Notifications On') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = isPinNotificationsActive == true || isPinNotificationsActive == false;
      //     }
      //     //----
      //     if (selectedCategories.includes('Online')) {
      //         matchesCategory = result.online === "YES";
      //     }
      //     if (selectedCategories.includes('Offline')) {
      //         matchesCategory = result.online === "NO";
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Offline')) {
      //         matchesCategory = result.online === "YES" || result.online === "NO";
      //     }
      //     //--
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.favorited || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = !result.favorited || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Favorited') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.favorited || isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Not Favorited') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = !result.favorited || isPinNotificationsActive == false;
      //     }
      //     //--
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.online === "YES" || result.favorited;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Favorited')) {
      //         matchesCategory = result.online === "NO" || result.favorited;
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.online === "YES" || !result.favorited;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Not Favorited')) {
      //         matchesCategory = result.online === "NO" || !result.favorited;
      //     }
      //     //--
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.online === "YES" || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications On')) {
      //         matchesCategory = result.online === "NO" || isPinNotificationsActive == true;
      //     }
      //     if (selectedCategories.includes('Online') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.online === "YES" || isPinNotificationsActive == false;
      //     }
      //     if (selectedCategories.includes('Offline') && selectedCategories.includes('Notifications Off')) {
      //         matchesCategory = result.online === "NO" || isPinNotificationsActive == false;
      //     }



      // }

      // if (selectedCategories.length > 0) {
      //     return matchesSearchQuery && matchesCategory;
      // }
      return true;
    });
    setUserDevices(filteredResults)
  };

  return (
    <div className={classes['activtyPage_container']}>
      <FilterUsersDeviceSearch Devices={userDevices} searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={"Search Sites..."} setIsLoading={setIsLoading} setFilteredResults={setUserDevices} handleFilterLogic={handleFilterLogic} categories={["Online", "Offline", "Notifications On", "Notifications Off", "Favorited", "Not Favorited"]} />

      {/* <UserDevicesTableLayout filtering={filtering} HandleTableFiltering={HandleTableFiltering} apiData={apiData} /> */}
      <Suspense fallback={
        <div className={classes["loadingSpinner-container"]}>
          <Oval stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} />
        </div>
      }>
        <Await resolve={UserDevices}>
          {(loadedUserDevices) =>
            // <GridList2 data={loadedSearchResults} />
            <div style={{ display: "flex", width: "100%" }}>
              <DevicesGrid data={loadedUserDevices} pageFetchedIndex={pageFetchedIndex} isUsersSites={true}/>
              {/* <h3>{isloading && "loading"}</h3> */}
            </div >
          }

        </Await>
      </Suspense>
    </div>
  )
}

export default UserDevicesPage

export async function loader() {
  const token = sessionStorage.getItem('token');
  const client = sessionStorage.getItem('client');
  const companyName = sessionStorage.getItem('companyName');
  const userID = sessionStorage.getItem('ClickedUserID');

  const apiData = {
    token,
    client
  }

  return defer({
    userDevices: getUsersDevices(apiData, userID, companyName)
  })
}
