import React, { Suspense, useEffect, useState } from 'react'
import classes from "./LogsTableLayout.module.css";
import Card from "./Card";
import Table from './Table/Table';
import tableClasses from './Table/EventsTable.module.css';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { Oval, Bars, ThreeDots } from 'react-loading-icons';
import Modal from '../Modal/Modal';
import alarm from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Alarm - Off.svg"
import cameraOn from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Camera - On.svg"
import cameraOff from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Camera - Off.svg"
import gateOpen from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Gate - Closed.svg"
import gateClosed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Gate - Open.svg"
import doorOpen from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Door - Open Black.svg"
import doorClosed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Door - Closed Black.svg"
import defaultOn from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/__Sensor - On.svg"
import defaultOff from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/__Sensor - Off.svg"
import armed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/armed.png"
import disarmed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/disarmed.png"
import pulsed from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/pulsed.png"
import locked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/locked.png"
import unlocked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlocked.png"
import otp from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/OTP.png"
import input_on from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/input_on.png"
import input_off from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/input_off.png"
import update from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/update.png"
import add from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/add.png"
import added from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/added.png"
import used_otp from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/used_otp.png"
import unlocked_unopened from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlocked_unopened.png"
import delete_pin from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/delete_pin.png"
import deleted from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/deleted.png"
import incorrect_pin from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/incorrect_pin.png"
import linked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/linked.png"
import unlinked from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/unlink.png"
import invited from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/invited.png"
import offline from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/offline.png"
import online from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/online.png"
import not_received from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/schedule_failed.png"
import accepted from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/accepted.png"
import left from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/left.png"
import camect from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/camect.png"
import copy_users from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/copyUsers.png"
import EventsTable from './Table/EventsTable';
import Export from '../UI/Cards/Logs Cards/Export/Export';
import BasicTable from './Table/Table';
import Button1 from '../UI/Button/Button1';
import SearchbarSmall from '../UI/Inputs/SearchbarSmall';
import { useSelector } from 'react-redux';
import ExportExcel from '../ExcelExport/ExcelExport';
import LinegraphPeaked from '../UI/Graphs/linegraphPeaked';

const LogsTableLayout = ({ data, isloading, Filtering }) => {

  //isloading = false;
  
  var m_names = new Array("January", "February", "March",
    "April", "May", "June", "July", "August", "September",
    "October", "November", "December");

    function formatDate(date) {

      var sup = "";
  
      if (date.getDate() == 1 || date.getDate() == 21 || date.getDate() ==31)
      {
          sup = "st";
      }
      else if (date.getDate() == 2 || date.getDate() == 22)
      {
           sup = "nd";
      }
      else if (date.getDate() == 3 || date.getDate() == 23)
      {
          sup = "rd";
      }
      else
      {
          sup = "th";
      }
  
      var curr_month = date.getMonth();
      var curr_year = date.getFullYear();

      var hours = date.getHours();
      var minutes = date.getMinutes();
      
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
       var strTime = hours + ':' + minutes + ' ' + ampm;
  
      return (date.getDate() + sup + " " + m_names[curr_month] + " " + curr_year + ", " + strTime);
  }

  const iconMapping = [
    armed,
    disarmed,
    pulsed,
    locked,
    unlocked,
    otp,
    input_on,
    input_off,
    update, 
    add, 
    added, 
    used_otp, 
    unlocked_unopened, 
    delete_pin, 
    deleted, 
    incorrect_pin, 
    linked, 
    unlinked, 
    invited, 
    offline, 
    online, 
    not_received, 
    accepted, 
    left, 
    camect, 
    copy_users
  ]

  let tableData=data;

  const tablecolumns = [
    {
      header: 'Activity',
      accessorKey: 'eventData',
      cell: (row) => {
        const subType = row.row.original.subType;
        const eventData = row.row.original.eventData;
        const recipientName = row.row.original.recipientName;
        const type = row.row.original.type

        let rowText;
        let iconPath;

        switch (subType) {
          case 'removed':
            rowText = recipientName + " " + subType
            break;
          case 'added':
            rowText = recipientName + " " + subType
            break;
          case 'changeRole':
            rowText = recipientName + "'s " + eventData;
            break;
          case "OPEN_CLOSE_LOCKED":
            rowText = eventData + " relocked";
            break;
          case "OTP_UNLOCK":
            rowText = eventData + " unlocked with OTP";
            break;
          case "PIN_UNLOCK":
            rowText = eventData
            break;
          case "MASTER_PIN_UNLOCK":
            rowText = eventData + " unlocked with master PIN";
            break;
          case "UNOPENED_LOCKED":
            rowText = eventData + " unlocked but not opened";
            break;
          case "INCORRECT_PIN":
            rowText = "Incorrect PIN was attempted on " + eventData;
            break;
          case "Unlock":
            rowText = eventData + " unlocked";
            break;
          case "DELETE_PIN":
            rowText = "Pincode deleted from " + eventData;
            break;
          case "FIRST_DURATION_UNLOCK":
            rowText = "Unlocked using Duration PIN"
            break;
          case "BLE_PIN_CREATED":
            rowText = "BLE Pin created for " + eventData
            break;
          default:
            rowText = eventData;
        }

        if (type === "Lock" || type === "IglooEvent"){
          if (subType === "Unlock"){
            iconPath = iconMapping[4]
          } else if (subType === "OPEN_CLOSE_LOCKED") {
            iconPath = iconMapping[3]
          } else if (subType === "OTP" || subType === "BLE_PIN_CREATED"){
            iconPath = iconMapping[5]
          } else if (subType === "OTP_UNLOCK" || subType === "PIN_UNLOCK" || subType === "FIRST_DURATION_UNLOCK" || subType === "FIRST_PERMANENT_UNLOCK"){
            iconPath = iconMapping[11]
          } else if (subType === "UNOPENED_LOCKED"){
            iconPath = iconMapping[12]
          } else if (subType === "DELETE_PIN"){
            iconPath = iconMapping[13]
          } else if (subType === "INCORRECT_PIN"){
            iconPath = iconMapping[15]
          } else if (subType === "LINK"){
            iconPath = iconMapping[16]
          } else if (subType === "UNLINK"){
            iconPath = iconMapping[17]
          }
        } else if (type === "Input") {
          if (subType === "Armed"){
            iconPath = iconMapping[7]
          } else {
            iconPath = iconMapping[6]
          }
        } else if (type === "output") {
          if (subType === "Armed"){
            iconPath = iconMapping[0]
          } else if (subType === "Disarmed"){
            iconPath = iconMapping[1]
          } else {
            iconPath = iconMapping[2]
          }
        } else if (subType === "update" || subType === "Link"){
          iconPath = iconMapping[8]
        } else if (subType === "add" || subType === "Set" || subType === "Add" || subType === "Added"){
          iconPath = iconMapping[9]
        } else if (subType === "added"){
          iconPath = iconMapping[10]
        } else if (subType === "delete" || subType === "removed" || subType === "Delete"){
          iconPath = iconMapping[14]
        } else if (subType === "LINK"){
          iconPath = iconMapping[16]
        } else if (subType === "invited"){
          iconPath = iconMapping[18]
        } else if (subType === "offline"){
          iconPath = iconMapping[19]
        } else if (subType === "online"){
          iconPath = iconMapping[20]
        } else if (type === "ScheduledAction"){
          iconPath = iconMapping[21]
        } else if (subType === "accepted"){
          iconPath = iconMapping[22]
        } else if (subType === "left"){
          iconPath = iconMapping[23]
        } else if (type === "Camect"){
          iconPath = iconMapping[24]
        } else if (subType === "copyDeviceUsers"){
          iconPath = iconMapping[25]
        }

        return (
            <div style={{ display: "flex",  alignItems: "center", gap: "10px" }}>
              <div style={{  height: "10px" }} />
              <img className={classes.img} src={iconPath}/>
              {rowText}
            </div> 
        )
      }
    },
    {
      header: 'Time',
      accessorKey: 'eventDate',
      cell: (row) => {
        const eventDate = new Date(row.row.original.eventDate)
        return (formatDate(eventDate))
      }
    },
    {
      header: 'User',
      accessorKey: 'user',
    }
  ];

  const [exportData, setExportData] = useState()

  const exportFormatFunction = (event) => {
    return {
      Date: event.eventDate,
      Time: event.eventTime,
      Acivity: event.eventData,
      User: event.user
    };
  };

  return (
    <div className={classes.table}>
      <div className={classes["tableCardLayoutUI-wrapper"]}>
        <div className={classes["top-wrapper"]}>
          {/* <div className={classes["info-bar"]}>
            <>
               {isloading && <ThreeDots stroke="var(--Primary-Color)" height={50} width={25} speed={1.5} />}
            </>
           </div>  */}
        </div>
        {isloading ? <div className={classes["loadingSpinner-container"]}>  <ThreeDots stroke="var(--Primary-Color)" height={200} width={100} speed={1.5} /> </div>
          :
          <BasicTable data={tableData} columns={tablecolumns} filtering={Filtering} rowClick={false} ShowPagination={true} PageSize={10} paginationOptions={[10, 20, 30]} tableClasses={tableClasses} setExportData={setExportData} exportFormatFunction={exportFormatFunction} />}
      </div>
    </div>
  )
}

export default LogsTableLayout;