import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "./SigFoxContentLayout.module.css";
import arrowIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";

import Modal from '../../../../../Modal/Modal';


import { useDispatch, useSelector } from 'react-redux';

import OTPIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Access.svg";
import RemoveCamectIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import GenerateLockOnTimePassCodeForm from '../../EditAutomations/GenerateLockOnTimePassCodeForm';
import { deleteCamectAPI, GenerateLockOTP, GenerateLockOTPAPI, removeLockAPI, unlinkSigfoxAPI, updateCamectRelationshipAPI } from '../../../../../../Api Methods/Api';

import LinkIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Link.svg"
import EditIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/EditView Black.svg"
import RefreshIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Camect/__Refresh.svg"
import Button2 from '../../../../Button/Button2';

import Button1 from '../../../../Button/Button1';

const SigFoxContentLayout = ({ sensor, pinName, setRefreshVirtualSources }) => {
    const { type, virtualInput } = sensor;

    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const apiData = {
        token,
        client
    }

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("SmartLock")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);

    const [showAddCamect, setShowAddCamect] = useState(false);

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandelResetModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);


        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setShowAddCamect(null);
        setModalPageIndex(null);
    }

    const dispatch = useDispatch();
    const DeviceDetails = useSelector(state => state.service.DeviceDetails)


    const handleShowAddCamectForm = () => {
        HandleCloseModal();
        // HandleSelectCamectModalForm(3, camect)
    }

    // // re-render modal to show new modal componet. 
    // useEffect(() => {
    //     console.log(modalPageIndex);
    //     switch (modalPageIndex) {
    //         case 1:
    //             setModalTitle("Link Switch/Input")
    //             console.log("here");
    //             setModalComponent(
    //                 <ul className={classes.PinsList}>
    //                     {Outputs.map((output) => (
    //                         <li>
    //                             <Button2 onClick={() => LinkCamect(output.Pin)}>
    //                                 {output.pinName}
    //                             </Button2>

    //                         </li>
    //                     ))}
    //                 </ul>
    //             )
    //             HandleShowModal();
    //             break;
    //     }

    // }, [modalPageIndex])

    // const HandleDeleteCamect = async () => {
    //     if (LinkedPins.length != 0) {
    //         setModalTitle("Important!");
    //         setModalComponent(
    //             <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
    //                 <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
    //                     <h4 style={{ margin: "0px" }}>{"Please unlink this camect from all switched/inputs before deletion"}</h4>
    //                 </div>
    //                 <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
    //                     <Button1 propsclassName={classes.CancelButton} onClick={HandleCloseModal}>Cancel</Button1>
    //                 </div>
    //             </div>
    //         )
    //     }
    //     else {
    //         const responseData = await deleteCamectAPI(apiData, DeviceUUID, camectID, dispatch);
    //         if (responseData.message == "sent") {
    //             HandleCloseModal();
    //             refreshCamects();
    //         }
    //     }
    // }

    // const LinkCamect = async (Pin) => {
    //     const pin = Pin.toString();
    //     const camectDetails = {
    //         "uuid": DeviceUUID,
    //         "id": camectID,       //The ID of the camect (set to "" if removing)
    //         "pin": pin
    //     }

    //     if (DeviceDetails.camectIntegrationType == 1) {
    //         const responseData = await updateCamectRelationshipAPI(apiData, camectDetails, dispatch);
    //         if (responseData) {
    //             HandleCloseModal();
    //             refreshCamects();
    //         }
    //     }
    // }

    const unlinkSigfox = async () => {
        const responseData = await unlinkSigfoxAPI(apiData, DeviceUUID, virtualInput, dispatch)
        // if (responseData) {
        //     HandleCloseModal();
        //     refreshCamects();
        // }
    }

    // const HandleShowLinkedPins = () => {
    //     console.log("here");
    //     setModalTitle("Unlink Switches/Inputs")
    //     setModalComponent(
    //         <ul className={classes.PinsList}>
    //             {LinkedPins.map((LinkedPin) => {
    //                 console.log(LinkedPin);
    //                 return (
    //                     <li>
    //                         <Button2 onClick={() => removeLinkedPin(LinkedPin.Pin)}
    //                             img={RemoveCamectIcon}>
    //                             {LinkedPin.pinName}
    //                         </Button2>
    //                     </li>
    //                 )
    //             })}
    //         </ul>
    //     )
    //     HandleShowModal();
    // }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: "270px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.CamectContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 style={{ margin: "0px" }}>{"Sigfox"}</h3>
                    <h3 className={classes.subtitle}>{type}</h3>
                </div>

                <div className={classes["right-wrapper"]}>

                    <div className={classes["text-container"]} >
                        <h3 className={classes.pinName}>{pinName.pinName} </h3>
                    </div>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} style={{ width: "30px", height: "30px", margin: "5px" }} src={RemoveCamectIcon} onClick={() => unlinkSigfox(2)} />
                    </div>
                </div>
            </div >

        </>
    )
}

export default SigFoxContentLayout;




