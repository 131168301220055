import React, { Fragment, useState, useEffect } from "react";
import ComponentCard from "../../Cards/ComponentCard/ComponentCard";

import PinInputList from "../PinInput/InputPinV2/InputList/PinInputList";
import PinOutputList from "../PinOut/OutputV2/OutputList/PinOutputList";

import classes from "./AppView.module.css";


import EditViewIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/EditView.svg";
//import PreviousPageIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/__Page - Back.svg";
import EditPinsView from "./EditPinsView";
import PinsView from "./PinsView";
import PreviousPageIcon from "../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Table Icons/Pagination Icon/back.png";

import { useSocketHook } from "../../../../Socket/useSocketHook";
import parsePinStates from "../../../../Socket/parsePinStates";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDeviceData } from "../../../../Api Methods/Api";

import { serviceActions } from "../../../../store/service-Slice";


const AppView = ({ singleDevicePins, deviceUUID, setHasDeviceChanged }) => {

    let isSocketConnected = useSelector(state => state.service.isSocketConnected);

    const [showEditPinView, setShowEditPinView] = useState(false);
    const [showEditPinForm, setShowEditPinForm] = useState(false);

    const HandleShowEditPinForm = () => {
        setShowEditPinForm((prevShowEditPinForm) => !prevShowEditPinForm)
    }

    const [sokectPinStates, setSokectPinStates] = useState();

    const token = sessionStorage.getItem('token');
    const client = sessionStorage.getItem('client');
    const DeviceUUID = sessionStorage.getItem('DeviceUUID');
    const dispatch = useDispatch();

    const apiData = {
        token,
        client
    }

    const HandleCheckUUID = (data) => {
        if (deviceUUID === data.uuid) {
            const pinStates = parsePinStates(data);
            setSokectPinStates(pinStates)
        }
    };


    const deviceChanged = (hasDeviceChanged) => {
        if (deviceUUID === hasDeviceChanged.uuid) {
            setHasDeviceChanged(true);
        }
    }
    const socketListeners = [{ socketlistener: "appPinStates", callback: HandleCheckUUID }, { socketlistener: "deviceChanged", callback: deviceChanged }];

    useSocketHook(isSocketConnected, socketListeners);

    const HandleToggleEditPinView = () => {
        setShowEditPinView((prevShowPinView) => !prevShowPinView);
        setShowEditPinForm(false);
    }
    return (
        <div className={classes["appView-container"]}>

            <div style={{ display: "flex" }}>
                {showEditPinForm && <div className={classes["appview-controls1"]}>
                    <button className={`${classes.ToggleEditPinView} ${showEditPinView && classes.ToggleEditPinViewActive}`} onClick={() => setShowEditPinForm(false)}>
                        <img className={classes.PreviousPageButtonImg} src={PreviousPageIcon} />
                    </button>

                </div>}

                <div className={classes["appview-controls2"]}>


                    <button className={`${classes.ToggleEditPinView} ${showEditPinView && classes.ToggleEditPinViewActive}`} onClick={HandleToggleEditPinView}>
                        <img className={showEditPinView ? classes.activeImg : classes.img} src={EditViewIcon} />
                    </button>
                </div>
            </div>
            {showEditPinView ? <EditPinsView singleDevicePins={singleDevicePins} HandleToggleEditPinView={HandleToggleEditPinView} showEditPinForm={showEditPinForm} setShowEditPinForm={setShowEditPinForm} HandleShowEditPinForm={HandleShowEditPinForm} />
                :
                <PinsView singleDevicePins={singleDevicePins} sokectPinStates={sokectPinStates} />
            }

        </div>
    );
}


export default AppView;

const HandleResponseData = (result) => {
    const OutPutPins = [5, 6, 13, 19];

    const {
        id,
        name,
        mac,
        key,
        ip,
        host,
        uuid,
        online,
        created,
        lastLoggedIn,
        status,
        tag,
        networkType,
        deviceDetails,
        firmware,
        type,
        numInput,
        numOutput,
        subGroup,
        deviceExtraDetails,
        latestFirmware,
        deviceName,
        iglooLocks } = result.data[0];

    // Passing Single Device Details to redux store to make these details availabe to other components. e.g. header
    const DeviceDetailsParsed = JSON.parse(deviceDetails);

    // const dispatch = useDispatch();

    const DeviceExtraDetailsParsed = JSON.parse(deviceExtraDetails);

    // dispatch(serviceActions.setDeviceDetails([DeviceDetailsParsed, id, DeviceExtraDetailsParsed]));

    const allPinsNotificationActive = DeviceDetailsParsed.allPinsNotificationActive;
    const allPinsNotificationSound = DeviceDetailsParsed.allPinsNotificationSound;
    const camectIntegrationType = DeviceDetailsParsed.camectIntegrationType;
    const singleDeviceName = DeviceDetailsParsed.name;
    const offlineNotificationActive = DeviceDetailsParsed.offlineNotificationActive;
    const offlineNotificationSound = DeviceDetailsParsed.offlineNotificationSound;
    const onlineNotificationActive = DeviceDetailsParsed.onlineNotificationActive;
    const onlineNotificationSound = DeviceDetailsParsed.onlineNotificationSound;
    const polarity = DeviceDetailsParsed.polarity;
    const polarityReason = DeviceDetailsParsed.polarityReason;

    const singleDeviceSettingData = { online, numOutput, numInput, allPinsNotificationActive, allPinsNotificationSound, camectIntegrationType, singleDeviceName, offlineNotificationActive, onlineNotificationActive, offlineNotificationSound, onlineNotificationSound, polarity, polarityReason, iglooLocks }

    // Parsing the Pins out into OutPut Pins / Input Pins - also added the pin number as a property to the OutPut Object.
    const DeviceOutputPins = OutPutPins.map((output) => { const value = DeviceDetailsParsed[output]; return { ...value, Pin: output }; }).filter((obj) => obj !== undefined);
    let InputPins;
    if (numInput != 0) {
        let DeviceInputPins;
        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            DeviceInputPins = InputsPins.map((input) => { const value = DeviceDetailsParsed[input]; return { ...value, Pin: input }; }).filter((obj) => obj !== undefined);
        }

        //Input Device Icons
        let InputDeviceIcons;
        if (numInput == 4) {
            const InputsPins = [21, 20, 16, 12];
            //Input Device Icons
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        } else if (numInput == 8) {
            const InputsPins = [21, 20, 16, 12, 4, 3, 2, 1];
            InputDeviceIcons = InputsPins.map((input) => JSON.parse(deviceExtraDetails)[input]).filter((value) => value !== undefined);
        }
        const arrayOfInputDeviceIcons = Object.values(InputDeviceIcons);
        const arrayOfInputDeviceIconsValues = arrayOfInputDeviceIcons.map(obj => obj.icon);

        // debugger;
        InputPins = DeviceInputPins.map((item, index) => {
            let icon = arrayOfInputDeviceIconsValues[index];
            if (icon.includes('.png')) {
                // //slice off .png 
                icon = icon.slice(0, -4) /*+ '.svg';*/
            } else {
                icon = icon /*+ '.svg';*/
            }

            return { ...item, icon };
        });

    }
    //Output Device Icons
    const OutputDeviceIcons = OutPutPins.map((output) => JSON.parse(deviceExtraDetails)[output]).filter((value) => value !== undefined);
    const arrayOfOutputDeviceIcons = Object.values(OutputDeviceIcons);
    const arrayOfOutputDeviceIconsValues = arrayOfOutputDeviceIcons.map(obj => obj.icon);

    const OutputPins = DeviceOutputPins.map((item, index) => {
        let icon = arrayOfOutputDeviceIconsValues[index];
        let DeviceUUID = uuid;
        let DeviceType = type;
        if (icon.includes('.png')) {
            icon = icon.slice(0, -4)
        } else {
            icon = icon
        }
        return { ...item, icon, DeviceUUID, DeviceType };
    });

    const ResponseData = [
        { OutputPins, InputPins, singleDeviceSettingData },
        { id, name, mac, key, ip, host, uuid, online, created, lastLoggedIn, status, tag, networkType, firmware, type, subGroup, numInput, numOutput, latestFirmware, deviceName }
    ]
    return ResponseData;
}