import React, { Children, useEffect, useState, useRef } from 'react'

import classes from "./IFTTTRuleLayout.module.css";
import arrowIcon from "../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import Pill_Widget from '../../../Widgets/Pill_Widget';
import Modal from '../../../../Modal/Modal';
import List from './List';
import AffectedSwitchesList from './AffectedSwitchesList';
import Button1 from '../../../Button/Button1';
import { setInputRuleApi } from '../../../../../Api Methods/Api';
import { useDispatch, useSelector } from 'react-redux';

const IFTTTRuleLayout = ({ listoptions, OutPutOptions, title, pinName, item, InputTypeText, InputType, InputRuleText, LinkOutPutsArray, OutPutsPinNames, InputsPinNames, isRuleAppliedToInput, fetchAutomations }) => {
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);

    const apiData = {
        token,
        client
    }

    //------------------------------- Modal Logic  ----------------------------------------------

    const ModalDialog = useRef();

    // call ref to clearStates method from child component ScheduledAction.
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();

        //reset states for scheduled Action
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            // Call the child method
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        //reset model screens
        HandelResetModal();
    }

    const [modalTitle] = useState("Edit IFTTT Rule")
    const [modalComponent, setModalComponent] = useState()
    //--------------------------------------------------------------------------------------------
    const [modalPageIndex, setModalPageIndex] = useState(null);

    // reset model when Escape key is pressed
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                // Handle the Escape key press here
                HandleCloseModal();
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    //constants for updating content on each modal page
    const [isFormValid, setIsFormValid] = useState(false);

    const [ListArray, setListArray] = useState(listoptions)
    const [modalPageMessage, setModalPageMessage] = useState();

    // constants for setInputRule fetch data - body 
    const isActive = parseInt(item.active);
    const [isAutomationActive, setIsAutomationActive] = useState(isActive);


    const [switchAutomationIsAppliedTo, setSwitchAutomationIsAppliedTo] = useState(pinName);
    const [switchAffected, setSwitchAffected] = useState([]);

    useEffect(() => {
        setSwitchAutomationIsAppliedTo(pinName)
    }, [pinName])

    useEffect(() => {
        setSwitchAffected(LinkOutPutsArray)
    }, [LinkOutPutsArray])

    const [whenAutomationShouldRun, setWhenAutomationShouldRun] = useState(InputTypeText);
    const [inputRule, setInputRule] = useState(InputRuleText);


    const HandelResetModal = () => {
        setModalComponent(null);
        setModalPageIndex(null);
        setListArray(listoptions);
        setModalPageMessage();
        setIsAutomationActive(isAutomationActive);
        setSwitchAutomationIsAppliedTo(pinName);
        setSwitchAffected(LinkOutPutsArray);
        setWhenAutomationShouldRun(InputTypeText);
        setInputRule(InputRuleText);
    }

    // re-render modal to show new modal componet. 
    useEffect(() => {
        if (modalPageIndex != null) {
            HandleModalPageUpdate(modalPageIndex);
        }

        //validation - dont allow user to set a rule that has the both switchAutomationIsAppliedTo & switchAfftected
        if (switchAutomationIsAppliedTo[0].pinName && switchAutomationIsAppliedTo[0].pinName == switchAffected[0] && switchAffected[0].pinName) {
            setSwitchAffected([{ "pinName": "" }])
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }, [modalPageIndex, isAutomationActive, switchAutomationIsAppliedTo, switchAffected, whenAutomationShouldRun, inputRule])
    const HandleModalPageUpdate = (modalPageIndex) => {
        switch (modalPageIndex) {
            case 0:

                setModalComponent(() => EditAutomation())
                break;
            case 1:
                setModalComponent(<List message={modalPageMessage} listItems={ListArray} prevPage={() => setModalPageIndex(0)} callback={setSwitchAutomationIsAppliedTo} modalpageIndex={modalPageIndex} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} />)
                break;
            case 2:
                setModalComponent(<AffectedSwitchesList pinName={switchAutomationIsAppliedTo} switchAffected={switchAffected} whenAutomationShouldRun={whenAutomationShouldRun} inputRule={inputRule} listItems={ListArray} message={modalPageMessage} prevPage={() => setModalPageIndex(0)} modalpageIndex={modalPageIndex} callback={setSwitchAffected} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} />)
                break;
            case 3:
                setModalComponent(<AffectedSwitchesList pinName={switchAutomationIsAppliedTo} switchAffected={switchAffected} whenAutomationShouldRun={whenAutomationShouldRun} inputRule={inputRule} listItems={ListArray} message={modalPageMessage} prevPage={() => setModalPageIndex(0)} modalpageIndex={modalPageIndex} callback={setWhenAutomationShouldRun} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} />)
                break;
            case 4:
                setModalComponent(<AffectedSwitchesList pinName={switchAutomationIsAppliedTo} switchAffected={switchAffected} whenAutomationShouldRun={whenAutomationShouldRun} inputRule={inputRule} listItems={ListArray} message={modalPageMessage} prevPage={() => setModalPageIndex(0)} modalpageIndex={modalPageIndex} callback={setInputRule} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} />)
                break;
        }
        HandleShowModal();
    }



    const EditAutomation = () => {
        return (
            <div className={classes.container}>
                {isRuleAppliedToInput && <button className={classes.listOption} onClick={() => setIsAutomationActive((prevIsAutomationActive) => (prevIsAutomationActive == true ? 0 : 1))}>
                    <h4>Enable this Automation?</h4>
                    <p>{isAutomationActive ? "Active" : "Inactive"}</p>
                </button>}


                <button className={classes.listOption} onClick={() => HandleNavigateToListPage(1, listoptions, "Which Switch do you want the automation on?")}>
                    <h4>Which Switch is the automation on?</h4>
                    <p>[{switchAutomationIsAppliedTo[0].pinName ? switchAutomationIsAppliedTo[0].pinName : switchAutomationIsAppliedTo}]</p>
                </button>

                <button className={classes.listOption} onClick={() => HandleNavigateToListPage(2, OutPutOptions, "Which Switch do you want the automation on?")}>
                    <h4 >Which Switch should be affected?</h4>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <p>[
                            {switchAffected && Array.isArray(switchAffected) && switchAffected.length > 0 ? switchAffected.map((Switch, index) => (
                                Switch.pinName + (switchAffected.length - 1 != index ? ",\u00A0" : "")
                            )) : LinkOutPutsArray}
                            ]</p>

                    </div>
                </button >

                {
                    isRuleAppliedToInput && <button className={classes.listOption} onClick={() => HandleNavigateToListPage(3, ["Armed", "Armed or Disarmed", "Disarmed"], "Which Switch do you want the automation on?")}>
                        <h4>When should the automation run?</h4>
                        <p>[{whenAutomationShouldRun ? whenAutomationShouldRun : InputRuleText}]</p>
                    </button>
                }

                {
                    isRuleAppliedToInput && <button className={classes.listOption} onClick={() => HandleNavigateToListPage(4, ["Match state", "Oppose state", "Flip their own state"], "Which Switch do you want the automation on?")}>
                        <h4>What should the switches do?</h4>
                        <p>[{inputRule ? inputRule : InputTypeText}]</p>
                    </button>
                }

                <div style={{ marginTop: !isRuleAppliedToInput && "160px", width: "100%", height: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Button1 propsclassName={classes.RemoveButton} onClick={() => DeleteRule(switchAutomationIsAppliedTo)} >{"Remove " + title}</Button1>
                    <Button1 disabled={switchAffected[0] && switchAffected[0].pinName == '' && true} propsclassName={classes.UpdateButton} onClick={() => UpdateRule(isAutomationActive, switchAutomationIsAppliedTo, switchAffected, whenAutomationShouldRun, inputRule)} >{"Update Rule"}</Button1>
                </div>
            </div >
        )
    }


    const HandleNavigateToListPage = (ModalPageIndex, array, ModalPageMessage) => {
        // filter out the pinName from the list of selectable options in modal page 2
        if (ModalPageIndex == 1) {
            let filteredArray;
            // on load pinName will be assigned to switchAutomationIsAppliedTo, if they are equal to one another then this means switchAutomationIsAppliedTo has not been updated yet
            if (switchAutomationIsAppliedTo == pinName) {
                filteredArray = array.filter(Pin => Pin.pinName != switchAutomationIsAppliedTo)
            } else {
                filteredArray = array.filter(Pin => Pin.pinName != switchAutomationIsAppliedTo[0].pinName)
            }
            setListArray(filteredArray);
        } else {
            setListArray(array);
        }

        if (ModalPageIndex == 2) {
            let filteredArray = array;
            // on load pinName will be assigned to switchAutomationIsAppliedTo, if they are equal to one another then this means switchAutomationIsAppliedTo has not been updated yet
            // if (switchAffected == LinkOutPutsArray) {
            //     console.log("here");
            //     filteredArray = array.filter(Pin => Pin.pinName != switchAffected)
            // } else {
            //     console.log("here");    
            //     filteredArray = array.filter(Pin => Pin.pinName != switchAffected[0].pinName)
            // }
            setListArray(filteredArray);
        } else {
            setListArray(array);
        }

        if (ModalPageIndex) {
            setModalPageIndex(ModalPageIndex);
        }
        if (ModalPageMessage) {
            setModalPageMessage(ModalPageMessage);
        }
    }


    const dispatch = useDispatch();

    const DeleteRule = async (input) => {

        const Input = input[0].Pin.toString();
        let body;
        if (isRuleAppliedToInput) {
            body = {
                uuid: DeviceUUID,
                input: Input,
                inputType: "0",
                inputRule: "0",
                outputs: "",
                active: "0"
            }
        } else {
            body = {
                uuid: DeviceUUID,
                input: Input,
                inputType: "0",
                inputRule: "0",
                outputs: Input,
                active: "0"
            }
        }

        const response = await setInputRuleApi(apiData, body, dispatch)
        if (response.rule == 'sent') {
            HandleCloseModal();
            fetchAutomations();
        }
    }

    const UpdateRule = async (isActive, SwitchAutomationIsAppliedTo, SwitchAffected, WhenAutomationShouldRun, InputRule) => {
        if (true) {
            let body = {
                uuid: DeviceUUID,
                input: 'null',
                inputType: "null",
                inputRule: "null",
                outputs: "null",
                active: isActive.toString()
            }

            //--------------------------------  get input ----------------------------------------
            let matchedPin;
            if (Array.isArray(SwitchAutomationIsAppliedTo)) {
                body.input = SwitchAutomationIsAppliedTo[0].Pin.toString();
                matchedPin = SwitchAutomationIsAppliedTo[0];
            } else {
                matchedPin = OutPutsPinNames.find(output => output.pinName === SwitchAutomationIsAppliedTo) ||
                    InputsPinNames.find(Input => Input.pinName === SwitchAutomationIsAppliedTo);
                body.input = matchedPin.Pin.toString();
            }

            if (matchedPin.pinName != pinName[0].pinName) {
                let body;
                if (isRuleAppliedToInput) {
                    body = {
                        "uuid": DeviceUUID,
                        "input": pinName[0].Pin,
                        "inputType": "0",
                        "inputRule": "0",
                        "outputs": pinName[0].Pin.toString(),
                        "active": "0"
                    }
                } else {
                    body = {
                        "uuid": DeviceUUID,
                        "input": pinName[0].Pin,
                        "inputType": "0",
                        "inputRule": "0",
                        "outputs": pinName[0].Pin.toString(),
                        "active": "0"
                    }
                }
                const response = await setInputRuleApi(apiData, body, dispatch)
            }
            if (Array.isArray(switchAffected)) {


                let stringOfOutputs = SwitchAffected.map(output => {
                    const foundOutput = OutPutsPinNames.find(item => item.pinName === output.pinName);
                    return foundOutput ? foundOutput.Pin : null;
                }).filter(Boolean).join(',');


                // if (isRuleAppliedToInput) {  // adding input Pin to start of outputs string
                // const Outputs = stringOfOutputs;
                // body.outputs = Outputs;
                // console.log(Outputs);
                // } else {
                const inputPinAsString = matchedPin.Pin;

                const existingString = stringOfOutputs;
                const newString = inputPinAsString + ",";
                const Outputs = newString + existingString;
                body.outputs = Outputs;
                // }




            } else {
                const foundOutput = OutPutsPinNames.find(item => item.pinName === switchAffected);

                // adding input Pin to start of outputs string
                const inputPinAsString = matchedPin.Pin;
                const existingString = foundOutput.Pin;
                const newString = inputPinAsString + ",";
                const Outputs = newString + existingString;
                body.outputs = Outputs;

            }
            if (isRuleAppliedToInput) {

                switch (WhenAutomationShouldRun) {
                    case "Armed":
                        body.inputType = "0";
                        //or
                        //InputTypeText = "Opend"; // type or because it's a lock
                        break;
                    case "Armed or Disarmed":

                        body.inputType = "1";
                        // or
                        // inpuy 8  InputTypeText = "Armed or Disarmed"; because it's set on a input?
                        // when in is Gate InputTypeText == Opened or closed
                        break;
                    case "Disarmed":
                        body.inputType = "2";
                        // input 6 InputTypeText = "Disarmed Test";
                        break;
                }
            }

            if (WhenAutomationShouldRun == 'Armed' || WhenAutomationShouldRun == 'match the state') {
                body.inputType = "0";
            } else if (WhenAutomationShouldRun == 'Armed or Disarmed' || WhenAutomationShouldRun == 'oppose the State') {
                body.inputType = "1";
            } else if (WhenAutomationShouldRun == 'Disarmed' || WhenAutomationShouldRun == 'Flip their own state') {
                body.inputType = "2";
            }
            //----------------------------------------------------------------------------------------

            //-------------------------------  get InputRule  -----------------------------------------
            if (InputRule == 'Match state' || InputRule == 'match the state') {
                body.inputRule = "0";
            } else if (InputRule == 'Oppose state' || InputRule == 'oppose the State') {
                body.inputRule = "1";
            } else if (InputRule == 'flip their own State' || InputRule == 'Flip their own state') {
                body.inputRule = "2";
            }
            //----------------------------------------------------------------------------------------


            //check if Input has been change is an input
            let isRuleAppliedToInput = InputsPinNames.find(Input => Input.Pin === parseInt(body.input));

            if (isRuleAppliedToInput != undefined) {
                isRuleAppliedToInput = true;
            } else {
                isRuleAppliedToInput = false;
            }

            const response = await setInputRuleApi(apiData, body, dispatch)
            if (response.rule == 'sent') {
                HandleCloseModal();
                fetchAutomations();
            }
        }

    }

    const pinStatuses = pinName.map((pin) => {
        return { pinStatusOn: pin.pinStatusOn, pinStatusOff: pin.pinStatusOff }
    })

    let inputType;

    if (!isRuleAppliedToInput) {
        inputType = inputType = pinStatuses[0].pinStatusOn
    } else {
        if (InputType == 0) {
            inputType = pinStatuses[0].pinStatusOn
        } else if (InputType == 1) {
            inputType = pinStatuses[0].pinStatusOn + " or " + pinStatuses[0].pinStatusOff
        } else if (InputType == 2) {
            inputType = pinStatuses[0].pinStatusOff
        }
    }

    let LinkedOutputsString = ""
    LinkOutPutsArray.map((output, index) => {
        const spacer = (index) != LinkOutPutsArray.length ? ", " : " "
        LinkedOutputsString += output.pinName + spacer
    })
    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "700px", minHeight: "420.5px" }} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>
            <div className={classes.GroupCardContentLayout} >

                <div className={classes["top-wrapper"]}>
                    <div className={classes["siteDetails-layout"]}>
                        <h3 style={{ margin: "0px" }}>{title}</h3>

                        <div className={classes["indicatorsCards-container"]}>
                            <Pill_Widget boolean={isActive} PillColor_IfTrue={"var(--Active-color)"} PillColor_IfFalse={"var(--Inactive-color)"} text_IfTrue={"Active"} text_IfFalse={"Inactive"} />
                        </div>
                    </div>

                </div>

                <div className={classes["middle-wrapper"]}>
                    <h3
                        style={{ fontWeight: "400", margin: "0px" }}
                        className={classes.tagName}>
                        {"When " + pinName[0].pinName + " is " + inputType + " - " + LinkedOutputsString + " will " + InputRuleText}
                    </h3>
                </div>

                <div className={classes["bottom-wrapper"]}>
                    <div className={classes.arrowButton} onClick={() => setModalPageIndex(0)}>
                        <img className={classes.Icon} src={arrowIcon} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default IFTTTRuleLayout;




