import React, { Children, useEffect, useState } from 'react'

import classes from "./GridList.module.css";

import Card from "./Card";
import GridPaginationControls from '../Table/Table/GridPaginationControls';
import SiteCardContentLayout from '../UI/DataLayouts/SiteCardContentLayout';
import useParseArrayDeviceData from '../hooks/useParseArrayDeviceData';
import DataManager from '../../hooks/DataManager';

const DevicesGrid = ({ data, HandleSaveSearchQuery, totalPages, setTotalPages, pageFetchedIndex, setPageFetchedIndex, pageSize, setPageSize, totalDevices, setTotalDevices, isUsersSites }) => {
    const [pageData, setPageData] = useState(data);
    const dataManager = new DataManager();

    const HandleNextPage = () => {
        setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex + 1)
        dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex + 1)
    }

    const HandlePreviousPage = () => {
        setPageFetchedIndex(prevPageFetchedIndex => prevPageFetchedIndex - 1)
        dataManager.setItem('pageIndex', prevPageFetchedIndex => prevPageFetchedIndex - 1)
    }

    const HandleSetPageIndex = (pageFetchedIndex) => {
        setPageFetchedIndex(pageFetchedIndex)
    }

    const HandlePageSize = (PageSize) => {
        setPageSize(PageSize);
        dataManager.setItem('pageSize', PageSize)
        HandlePageCount(totalPages);
        dataManager.setItem('totalPages', totalPages)
    }

    const HandlePageCount = (totalPages) => {
        setTotalPages(totalPages);
    }

    useEffect(() => {
        setPageData(data);
        dataManager.setItem('pageIndex', pageFetchedIndex)
    }, [pageFetchedIndex, pageSize, data])

    const parsedData = useParseArrayDeviceData(pageData);

    return (
        <div className={classes["grid-container"]}>
            <ul className={classes["grid-ul"]} style={{ display: parsedData.length < 5 && "flex", gap: parsedData.length < 5 && "54px" }}>
                {Array.isArray(parsedData) && parsedData.map((cardData, index) => (
                    <li className={classes["grid-li"]} key={index}>
                        <Card className={classes.card} index={index} color={cardData.type} isOnline={cardData.online}>
                            <SiteCardContentLayout cardData={cardData} HandleSaveSearchQuery={HandleSaveSearchQuery} /*pinStates?*/ />
                        </Card>
                    </li>
                ))}
            </ul>
            {!isUsersSites && (
                <GridPaginationControls 
                    pageIndex={pageFetchedIndex} 
                    nextPage={HandleNextPage} 
                    previousPage={HandlePreviousPage} 
                    setPageIndex={HandleSetPageIndex} 
                    pageCount={totalPages} 
                    setPageSize={HandlePageSize} 
                    PageSize={pageSize} 
                    totalDevices={totalDevices} 
                    setTotalDevices={setTotalDevices} 
                />
            )}        
        </div>
    )
}

export default DevicesGrid;
