import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Button1 from '../UI/Button/Button1';
import ExportIcon from "../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Export.svg"
const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];



const ExportExcel = ({ excelData, fileName, title, subTitle, colWidths, style }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UFT-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {

        // let titleRow = 'A1';
        // // let subTitleRow = subTitle ? 'A2' : null;
        // let dataRow = subTitle ? 'A3' : 'A2';
        // let dataRowNumber = subTitle ? 'A3' : 'A2';

        // Create worksheet from modified data
        const ws = XLSX.utils.json_to_sheet([], { origin: 'A2' });

        // Add title rows at the beginning
        XLSX.utils.sheet_add_aoa(ws, [[title], []], { origin: 'A1' });

        // Add subTitle rows at the beginning
        // XLSX.utils.sheet_add_aoa(ws, [[subTitle], []], { origin: 'A2' });

        //Add data row underneath title 
        XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: false });

        // Merge cells for the title row
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: colWidths.length - 1 } }
        ];

        // Style the title row
        ws['A1'].s = {
            font: {
                name: 'Arial',
                sz: 14,
                bold: true,
                color: { rgb: "FFFFFF" }
            },
            fill: {
                fgColor: { rgb: "03afff" }
            },
            alignment: {
                horizontal: 'center'
            }
        };

        //  // Style the subTitle row
        //  ws['A2'].s = {
        //     font: {
        //         name: 'Arial',
        //         sz: 14,
        //         bold: true,
        //         color: { rgb: "FFFFFF" }
        //     },
        //     fill: {
        //         fgColor: { rgb: "03afff" }
        //     },
        //     alignment: {
        //         horizontal: 'center'
        //     }
        // };


        // Style the headers
        for (let i = 0; i < colWidths.length - 1; i++) {
            ws[letters[i] + "2"].s = {
                font: {
                    sz: 12,
                    bold: true,
                    // color: { rgb: "111111" }
                },
                // fill: {
                // fgColor: { rgb: "ffffff" }
                // },
            };
        }

        //set column with of first six columns
        ws['!cols'] = colWidths || [
            { wch: 15 },
            { wch: 15 },
            { wch: 40 },
            { wch: 30 },
        ];;


        // Create workbook and add worksheet to it
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        // Write the workbook to a buffer
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create a Blob from the buffer
        const data = new Blob([excelBuffer], { type: fileType });

        // Save the file
        FileSaver.saveAs(data, fileName + fileExtension);
    }
    return <Button1 style={style} type="button" variant="contained" img={ExportIcon} onClick={(e) => exportToExcel(fileExtension)}>Excel Export</Button1>
}


export default ExportExcel;


