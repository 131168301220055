import React, { useState, useRef, useEffect } from 'react';
import classes from './PowerSearch.module.css'

import SearchBar from './SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { PowerSearchAPI } from '../../Api Methods/Api';
import DataManager from '../../hooks/DataManager';

const PowerSearch = ({ placeholder, searchQuery, categoryFilter, setSearchQuery, setIsLoading, setFilteredResults, handleFilterLogic, isGetAllDevicesPressed, setIsGetAllDevicesPressed, filteredResults, sortBy, hasCachedSites, pageSize, pageFetchedIndex, setTotalDevices, setTotalPages }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const dataManager = new DataManager();

    const [hasChange, setHasChange] = useState(false);

    pageSize = dataManager.getItem('pageSize');
    const pageNumber = pageFetchedIndex;

    const ApiData = {
        token,
        client
    }

    const formRef = useRef();
    const powerSearchTimer = useRef();
    const prevSearchQueryRef = useRef(searchQuery);

    useEffect(() => {



        setCategoryFilters()
        setSort()

        const HandlePowerSearch = async (ApiData, query, powerSearchTimer) => {
            setIsLoading(true);
            powerSearchTimer.current = Date.now();
            const online = dataManager.getItem('online');
            const offline = dataManager.getItem('offline');
            const noTag = dataManager.getItem('noTag');
            const sort = dataManager.getItem('sort');
            const triggerTime = powerSearchTimer.current;
            const responseData = await PowerSearchAPI(ApiData, query, pageSize, pageNumber, online, offline, noTag, sort, dispatch);
            const Sites = responseData[0].devices;
            const totalSites = responseData[0].totalDevices["COUNT(*)"];
            setTotalDevices(totalSites)
            if ((Math.ceil(totalSites / pageSize)) < 1) {
                setTotalPages(1)
            } else {
                setTotalPages(Math.ceil(totalSites/ pageSize));
            }

            if (powerSearchTimer.current == triggerTime) {
                handleFilterLogic(Sites, searchQuery, categoryFilter, sortBy);
                if (responseData != null) {
                    setIsLoading(false);
                }
            }
        }

        if (pageFetchedIndex !== dataManager.getItem('pageIndex')){
            HandlePowerSearch(ApiData, searchQuery, powerSearchTimer);
        }

        const delayDebounceFn = setTimeout(() => {
            if (searchQuery === "" || searchQuery === " " || searchQuery === null){
                //nothing entered - get all sites
                dataManager.setItem('getAllSites', true);
                setIsGetAllDevicesPressed(true);
                dataManager.setItem('searchQuery', searchQuery);
                //setHasChange(false)
            } else {
                //something entered
                dataManager.setItem('getAllSites', false);
                setIsGetAllDevicesPressed(false);
                //setHasChange(false)
                if (searchQuery !== dataManager.getItem('searchQuery')){
                    HandlePowerSearch(ApiData, searchQuery, powerSearchTimer);
                    dataManager.setItem('searchQuery', searchQuery);
                    //setHasChange(false)
                } else {
                    //search hasnt changed but now a filter or sort has been applied
                    //check length of previous filters from before a site was clicked, to avoid a redraw
                    if ((searchQuery === dataManager.getItem('searchQuery')) 
                        && ((categoryFilter.length > 0 && categoryFilter.length != dataManager.getItem('categoryFilterLength')) 
                        || (sortBy.length > 0 && sortBy !== 'all' && sortBy.length != dataManager.getItem('sortByLength')))){

                        HandlePowerSearch(ApiData, searchQuery, powerSearchTimer);
                        dataManager.setItem('searchQuery', searchQuery);
                        dataManager.setItem('categoryFilterLength', categoryFilter.length)
                        dataManager.setItem('sortByLength', sortBy.length)
                    } 
                    else { 
                        //did have a category but now doesn't
                        if ((dataManager.getItem('categoryFilterLength') !== categoryFilter.length) || (dataManager.getItem('sortByLength') !== sortBy.length)){
                            //filters have been changed, update
                            HandlePowerSearch(ApiData, searchQuery, powerSearchTimer);
                            dataManager.setItem('categoryFilterLength', categoryFilter.length)
                            dataManager.setItem('sortByLength', sortBy.length)
                        } 
                    }
                }
            }
        }, 1700);
        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, pageFetchedIndex, categoryFilter, sortBy]);

    function setCategoryFilters() {
        dataManager.setItem('online', 0);
        dataManager.setItem('offline', 0);
        dataManager.setItem('noTag', 0);

        categoryFilter.forEach((filter) => {
            switch (filter) {
                case "Online": {
                    dataManager.setItem('online', 1);
                    //setHasChange(true);
                    break;
                }
                case "Offline": {
                    dataManager.setItem('offline', 1);
                    //setHasChange(true);
                    break;
                }
                case "No Tag": {
                    dataManager.setItem('noTag', 1);
                    //setHasChange(true);
                    break;
                }
                case "all": {
                    dataManager.setItem('online', 0);
                    dataManager.setItem('offline', 0);
                    dataManager.setItem('noTag', 0);
                    //setHasChange(true);
                    break;
                }
                default: {
                    dataManager.setItem('online', 0);
                    dataManager.setItem('offline', 0);
                    dataManager.setItem('noTag', 0);
                    //setHasChange(true);
                    break;
                }
            }
        });
    }

    function setSort() {
        dataManager.setItem('sort', -1)
        switch (sortBy[0]) {
            case 'Tag - Asc': {
                dataManager.setItem('sort', 0);
                //setHasChange(true);
                break;
            }
            case "Tag - Desc": {
                dataManager.setItem('sort', 1);
                //setHasChange(true);
                break;
            }
            case "Firmware - Asc": {
                dataManager.setItem('sort', 2);
                //setHasChange(true);
                break;
            }
            case "Firmware - Desc": {
                dataManager.setItem('sort', 3);
                //setHasChange(true);
                break;
            }
            case "User Count - Asc": {
                dataManager.setItem('sort', 4);
                //setHasChange(true);
                break;
            }
            case "User Count - Desc": {
                dataManager.setItem('sort', 5);
                //setHasChange(true);
                break;
            }
            default: {
                dataManager.setItem('sort', -1);
                //setHasChange(true);
                break;
            }
        }
    }
    return (
        <form className={classes.form} ref={formRef}>
            <SearchBar
                text="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "500px" }}
                onClear={() => setSearchQuery("")}
            />

        </form>
    );
};

export default PowerSearch;
