import React, { useEffect, useState } from 'react'

import classes from "./AffectedSwitchesList.module.css"
import List from './List'
import ListOption from './ListOption'

const AffectedSwitchesList = ({ pinName, switchAffected, whenAutomationShouldRun, inputRule, listItems, message, prevPage, modalpageIndex, callback, OutPutsPinNames, InputsPinNames }) => {

    const [SwitchAffected, setSwitchAffected] = useState(modalpageIndex && modalpageIndex != 2 ? switchAffected : " - ")
    const [WhenAutomationShouldRun, setWhenAutomationShouldRun] = useState(whenAutomationShouldRun)
    const [InputRule, setInputRule] = useState(inputRule)

    const [initialLoad, setInitialLoad] = useState(true);

    const [pinNamesString, setPinNamesString] = useState(switchAffected);

    useEffect(() => {
        if (switchAffected && !initialLoad) {
            setSwitchAffected(switchAffected)
        }
        setInitialLoad(false);

        if (whenAutomationShouldRun) {
            setWhenAutomationShouldRun(whenAutomationShouldRun)
        }

        if (inputRule) {
            setInputRule(inputRule)
        }

        if (switchAffected && Array.isArray(switchAffected)) {
            setPinNamesString(switchAffected.map(obj => obj.pinName).join(', '));
        }


    }, [switchAffected, whenAutomationShouldRun, inputRule])


    return (
        <div className={classes.container}>
            {/* Back button */}
            {prevPage && <div className={classes.backButton} onClick={() => prevPage(false)} >{"<"}</div>}
            <ListOption optionText={"When [ " + (pinName[0].pinName || pinName) + " ] is " + WhenAutomationShouldRun + " [" + pinNamesString + "] will " + InputRule + "."} propsClassName={classes.infoBox} />
            <List message={message} listItems={listItems} pinName={pinName} modalpageIndex={modalpageIndex} prevPage={prevPage} callback={callback} OutPutsPinNames={OutPutsPinNames} InputsPinNames={InputsPinNames} />
        </div>
    )
}

export default AffectedSwitchesList