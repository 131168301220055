import React, { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeoutputPinState } from "../../../../../../store/service-actions"
import Card from "../../../../Cards/Card";

import classes from "./PinOutput.module.css";

import InactiveIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/default - Off.svg";
import HiddenIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/HiddenPin.svg";

import pulseIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/Pin/__Switch - Pulse.svg";
import { switchPulse } from "../../../../../../Api Methods/Api";
import useUnixTimestampConverter from "../../../../../hooks/UseUnixTimestampConverter";
import useTimeDifference from "../../../../../hooks/UseTimeDifference";

import LockIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"

const PinOutputPulseSocket = ({ OutPutPin, polarity, SmartLock }) => {
    // console.log("Pulse");
    const dispatch = useDispatch();
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);

    const { pinName, pinStatusOff, pinStatusOn, pinState, icon, DeviceUUID, Pin, active, type, DeviceType, length, lastSwitchTime } = OutPutPin;
    const lowerCaseStringIcon = icon.toLowerCase();
    const image = lowerCaseStringIcon + " - Off";
    const otherStateImage = icon + " - On";
    const staticPath = "/assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/PinIcons/";

    const imageFromPath = `${process.env.PUBLIC_URL}${staticPath}${image}.svg`
    let otherStateImageFromPath = `${process.env.PUBLIC_URL}${staticPath}${otherStateImage}.svg`;

    const [initialPinState] = useState(polarity === 1 ? pinState : !pinState)

    const [PinState, setPinState] = useState();
    const [timeLeft, setTimeLeft] = useState();
    const [buttonClicked, setButtonClicked] = useState(false)
    const [pinStateLoading, setPinStateLoading] = useState(false);




    //store first pinState  - initialPinState
    // get pulsetime legnth =  pulse length
    // start timer - change pulse state
    const timerLengthInSeconds = length;


    //change timer state

    const startPulseTimer = (time) => {
        setTimeLeft(time || length);
        setPinState(prevPinState => !prevPinState);
        let timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft <= 1) {
                    clearInterval(timer);
                    setPinState((prevPinState) => !prevPinState);
                    setButtonClicked(false)
                    return 0; // Ensure timeLeft doesn't go below zero
                } else {
                    return prevTimeLeft - 1;
                }
            })
        }, 1000)

    }

    const HandlePinClick = async () => {
        setPinStateLoading(true);
        setButtonClicked(true);

        const apiData = {
            token,
            client,
        }

        const response = await switchPulse(apiData, userID, Pin, length * 1000, DeviceUUID, type, dispatch)
        if (response.pulse == "sent") {
            startPulseTimer();
            setPinStateLoading(false);
        } else {
            setPinStateLoading(false);
        }
    }

    //-- Socket: if pinState changes, and is different from initialPinState 
    useEffect(() => {
        // Only set the timeout if pinState has changed
        if (!buttonClicked && pinState != initialPinState) {
            setButtonClicked(true);
            startPulseTimer();
        }
    }, [pinState, timerLengthInSeconds]);

    //-- fetch: if socket does not connect
    useEffect(() => {
        const datePulseClicked = new Date(lastSwitchTime * 1000)
        const datePulseFinished = new Date(lastSwitchTime * 1000).setSeconds(datePulseClicked.getSeconds() + length)
        var now = Date.now()
        let difference;

        if (!buttonClicked && now < datePulseFinished) {
            setButtonClicked(true)
            difference = Math.round((datePulseFinished - now) / 1000)
            startPulseTimer(difference)
        }

    }, [lastSwitchTime, pinState])

    return (
        <button className={`${classes["pin-switch"]} ${!active && classes["disabled-hover"]}`} style={{ opacity: !active && "30%", backgroundColor: SmartLock != null && "var(--PinLockColor)" }} disabled={buttonClicked || SmartLock != null} onClick={HandlePinClick}>
            {!active &&
                <div className={classes["overlay"]}>
                    <div className={classes.line} />
                    <img className={classes.HiddenIcon} src={HiddenIcon}></img>
                </div>}


            <div className={classes.top}>
                <h3>{pinName}</h3>
                {SmartLock == null && <h3 className={!pinStateLoading ? active ? PinState ? classes.On : classes.Off : classes.inactive : classes.loading}>
                    {active ? PinState ? "Pulsing" : "Not Pulsing" : "Inactive"}
                </h3>}
            </div>

            {/* <div className={classes.bottom}>
                {PinState ? <div className={classes.timer} >{timeLeft}</div> :
                    <img className={`${classes.img} ${classes["pulse-img"]}`} src={pulseIcon}></img>
                }
            </div> */}


            {SmartLock != null ?
                <div className={classes.bottom}>
                    <img className={classes.LockIcon} src={LockIcon} />
                </div>
                :
                <div className={classes.bottom}>
                    {PinState ? <div className={classes.timer} >{timeLeft}</div> :
                        <img className={`${classes.img} ${classes["pulse-img"]}`} src={pulseIcon}></img>
                    }
                </div>
            }
        </button>
    );
}

export default PinOutputPulseSocket;