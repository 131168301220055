import React, { useState, useEffect } from 'react';
import { socket } from './socket';
import { ConnectionState } from './ConnectionState';
import { ConnectionManager } from './ConnectionManager';

import { useDispatch } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import parsePinStates from './parsePinStates';
import { redirect, useNavigate } from 'react-router-dom';

export default function useSocketManager() {

  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const [isConnected, setIsConnected] = useState(socket.connected);
  const dispatch = useDispatch();

  const navigate = useNavigate();


    // Max number of reconnection attempts
    const maxReconnectAttempts = 5;

    function onConnect() {
      setIsConnected(true);

      // if (!socket.connected && reconnectAttempts < maxReconnectAttempts) {
      //   console.log("here");
      //   setReconnectAttempts(prevAttempts => prevAttempts + 1);

      //   dispatch(uiActions.renderNotification({
      //     status: 'pending',
      //     title: "Attempting to reconnect...",
      //     message: `(Attempt ${reconnectAttempts + 1})`,
      //   }));
      // } else if (!socket.connected && reconnectAttempts >= maxReconnectAttempts) {
      //   navigate('/logout');
      // }
    }

    function onDisconnect() {
      setIsConnected(false);
      dispatch(
        uiActions.renderNotification({
          status: 'successful',
          title: "Socket Disconnected",
          // message: "",
        })
      );
    }

    function onDeviceOnline(data) { // { user: 0 uuid: "0e56b353-c07c-4c14-aa6c-3c672de9dd78" }

      dispatch(
        uiActions.renderNotification({
          status: 'successful',
          title: "Device Online ",
          message: "Device Online: " + data.uuid,
        })
      );
    }

    function onDeviceOffline(data) {

      dispatch(
        uiActions.renderNotification({
          status: 'successful',
          title: "Device Offline ",
          message: "Device Offline: " + data.uuid,
        })
      );
    }

    function onConnectionError(err) {

      // some additional context, for example the XMLHttpRequest object
      // console.log(err.context);

      dispatch(
        uiActions.renderNotification({
          status: 'error',
          title: "Socket Connection Error",
          message: "Attempting to reconnect...",
        })
      );
    }

  useEffect(() => {
    socket.emit("app")
    //listners
    socket.on('connect', onConnect);  // listen for connected, if true run onConnect
    socket.on('disconnect', onDisconnect); // listen for disconnect, if true run onDisconnect
    socket.on("connect_error", (err) => onConnectionError(err));
    // socket.on("deviceOnline", (data) => onDeviceOnline(data));
    // socket.on("deviceOffline", (data) => onDeviceOffline(data));

    return () => {
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on("connect_error", onConnectionError);
      // socket.off('deviceOnline', onDeviceOnline);
      // socket.off('deviceOffline', onDeviceOffline);
    };

  }, [socket])

  return isConnected;
}


