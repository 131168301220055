import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "./SiteCardContentLayout.module.css";
import StatusIndicatorCard from '../../../components/UI/Cards/StatusIndicatorCard/StatusIndicatorCard';
import FavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - On.svg";
import UnFavouritedIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Favourite - Off.svg";
import ActiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - On.svg";
import InactiveNotificationBellIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Notification - Off.svg";
import arrowIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import diableNotificationIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import SettingsCog from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/gear.png"
import SuspendDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Locked.svg"
import UnSuspendDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Unlocked.svg"
import ClearLogsIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import ChangeOwnerIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Change User.svg"
import ChangeCamectIntergrationTypeIcon_Off from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - Off.svg";
import ChangeCamectIntergrationTypeIcon_On from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Camera - On.svg";
import CopyDeviceSettingsIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Logs.svg";
import RebootDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__OffButton.svg";
import ResetDeviceIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Refresh.svg"
import UserIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/user.png"
import ChangeTagIcon from "../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/__Edit-93.svg"
import CompanyIndicatorCard from '../Cards/StatusIndicatorCard/CompanyIndicatorCard';
import OutputPinCircle from './OutputPinCircle';
import InputPinCircle from './InputPinCircle';
import CustomLink from '../../CustomLink/CustomLink';
import Pill_Widget from '../Widgets/Pill_Widget';
import DropdownList from '../Cards/DropdownList';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom';
import { serviceActions } from '../../../store/service-Slice';
import { changeCamectIntegration, clearDeviceLogs, copyIPIOSettings, getDevices, rebootIPIODevice, resetIPIODevice, suspendDevice } from '../../../Api Methods/Api';
import DropDownForm from '../../forms/DropDownForm';
import useClickOutside from '../../hooks/useClickOutside';
import Modal from '../../Modal/Modal';
import InputForm from '../../forms/InputForm';
import { uiActions } from '../../../store/ui-slice';
import { Oval } from 'react-loading-icons';
import SetTag from '../../Modal/ModalLayouts/SetTag';
import Button1 from '../Button/Button1';

const SiteCardContentLayout = ({ cardData, HandleSaveSearchQuery }) => {
    const navigation = useNavigation();
    let { id, mac, key, ip, host, uuid, online, created, lastLoggedIn, tag, networkType, deviceDetails, firmware, type, numInput, numOutput, subGroup, deviceExtraDetails, latestFirmware, OutputPins, InputPins, singleDeviceSettingData, userCount, active, deviceName } = cardData;
    const [status, setStatus] = useState(cardData.status)
    const [name, setName] = useState(cardData.name)


    if (deviceName != ""){
        tag = deviceName
    }

    const [updateTag, setUpdateTag] = useState(tag)

    useEffect(() => {
        setUpdateTag(tag)
        setName(cardData.name)
        setStatus(cardData.status);
    }, [cardData])

    const DeviceDetails = JSON.parse(deviceDetails)
    const [camectIntegationType, setCamectIntegationType] = useState(DeviceDetails.camectIntegrationType)
    const client = useSelector(state => state.auth.client);
    const companyName = useSelector(state => state.auth.companyName);
    const token = useSelector(state => state.auth.token);
    const userID = useSelector(state => state.auth.userID);
    const userRole = useSelector(state => state.auth.role);
    const groups = useSelector(state => state.auth.groups);
    const parsedGroups = JSON.parse(groups);
    const groupBreadCrumb = parsedGroups.find((group) => group.name === name);
    const groupPath = groupBreadCrumb.group_path;
    const GroupNamesArraySplit = groupPath.split(">");
    const GroupNamesArray = GroupNamesArraySplit.slice(-3);
    const apiData = {
        token,
        client
    }

    const [openDropdownRowId, setOpenDropdownRowId] = useState(null);
    const NotificationCounterRef = useRef("NotificationCounter");
    const buttonRef = useRef("DeviceCardSettingCog");
    const dropdownRef = useRef("DropDownMenu");

    useClickOutside(NotificationCounterRef, buttonRef, dropdownRef, () => {
        setOpenDropdownRowId(null);
    });

    const handleSettingsClick = (rowId) => {

        // If the clicked dropdown is already open, close it
        if (openDropdownRowId === rowId) {
            setOpenDropdownRowId(null);
        } else {
            // Otherwise, open the clicked dropdown
            setOpenDropdownRowId(rowId);
        }
    };

    const ModalDialog = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
    }

    const [modalTitle, setModalTitle] = useState("title")
    const [modalComponent, setModalComponent] = useState()

    let items = [
        {
            image:
                status !== "suspended"
                    ? SuspendDeviceIcon
                    : UnSuspendDeviceIcon,
            title:
                status !== "suspended"
                    ? "Suspend Site"
                    : "Unsuspend Site",
        },
        {
            image: ClearLogsIcon,
            title: "Clear Logs",
        },
        {
            image: ChangeTagIcon,
            title: "Change Tag",
        },
    ];

    const Firmware = firmware.split('.')
    const firmwareMajor = Firmware[0]
    const firmwareMinor = Firmware[1]
    const DeviceType = type;
    const isOnline = online == "YES" ? true : false;

    if (companyName == "EyeSpyFX") {
        items.splice(3, 0, {
            image: ChangeOwnerIcon,
            title: "Change Owner",
        });
    }

    // Conditionally push the "Change Owner" object based on firmware version and device type
    if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 9)) && DeviceType != 'V') {
        items.splice(4, 0, {
            image: camectIntegationType == 2 ? ChangeCamectIntergrationTypeIcon_On : ChangeCamectIntergrationTypeIcon_Off,
            title: "Change Camect Integration Type",
        },);
    }

    if ((firmwareMajor > 1 || (firmwareMajor == 1 && firmwareMinor >= 7))) {
        items.splice(5, 0, {
            image: CopyDeviceSettingsIcon,
            title: "Copy site settings",
        });
    }

    if (isOnline) {
        items.splice(6, 0, {
            image: RebootDeviceIcon,
            title: "Reboot Site",
        },
        {
            image: ResetDeviceIcon,
            title: "Reset Site",
        });
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const HandleUipiodOnClick = (DeviceUUID) => {

        dispatch(serviceActions.setDeviceUUID(DeviceUUID));
        navigate("/portal/SiteView/" + DeviceUUID);
    }

    const UserActionHandler = (userAction, suspend, DeviceId, DeviceUUID, userID, DeviceName, DeviceKey, camectIntegationType) => {
        switch (userAction) {
            case 'Unsuspend Site':
            case 'Suspend Site':

                const SuspendDevice = async () => {
                    const response = await suspendDevice(apiData, suspend, DeviceId, dispatch);

                    if (response) {
                        if (status == "suspended") {
                            setStatus("available")
                        } else {
                            setStatus("suspended")
                        }
                        setOpenDropdownRowId(null);
                    }
                }
                SuspendDevice();
                break;
            case 'Clear Logs':
                const ClearLogs = () => {
                    setOpenDropdownRowId(null);

                    const HandleClearLogs = async () => {
                        const response = await clearDeviceLogs(apiData, DeviceUUID, userID, dispatch);
                        if (response) {
                            HandleCloseModal();
                        }
                    }

                    const Cancel = () => {
                        HandleCloseModal();
                    }

                    setModalTitle("Clear Logs");
                    setModalComponent(
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "content", justifyContent: "center", width: "100%", gap: "20px" }}>
                            <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "3px" }}>
                                <h4 style={{ margin: "0px" }}>{"Are you sure you want to clear Device: " + key + " Log's. This will be"}</h4>
                                <h4 style={{ margin: "0px", color: "red" }}>Permanent</h4>
                                <h4 style={{ margin: "0px" }}>!</h4>
                            </div>
                            <div style={{ display: "flex", alignItems: "content", justifyContent: "center", width: "100%", gap: "10px" }}>
                                <Button1 propsclassName={classes.CancelButton} onClick={Cancel}>Cancel</Button1>
                                <Button1 propsclassName={classes.ClearLogs} onClick={() => HandleClearLogs()}>Clear Logs</Button1>
                            </div>
                        </div>
                    )
                    HandleShowModal()
                }
                ClearLogs();
                break;
            case 'Change Tag':
                const ChangeTag = async () => {
                    setOpenDropdownRowId(null);
                    setModalTitle("Change Tag")
                    setModalComponent(<SetTag placeholder={tag} DeviceUUID={DeviceUUID} HandleCloseModal={HandleCloseModal} dispatch={dispatch} setUpdateTag={setUpdateTag} />)
                    HandleShowModal()
                }
                ChangeTag();
                break;
            case 'Change Owner':
                const ChangeOwner = async (DeviceKey) => {
                    setModalTitle("Change owner of: " + DeviceKey)
                    setModalComponent(<DropDownForm apiData={apiData} DeviceUUID={DeviceUUID} companyName={name} NotificationTitle={tag || key} label={"Please select a new device owner below"} dropDownOptions={['EyeSpyFX', 'RE:SURE', 'PARFAS', 'PORTAL_SECURITY', 'DCC', 'SOLO', 'CSC', 'CPS', 'EES', 'WEEBIG', 'KNOWIO', '12TH_LOCK']} HandleCloseModal={HandleCloseModal} setName={setName} />)
                    setOpenDropdownRowId(null);
                    HandleShowModal()
                }
                ChangeOwner(DeviceKey);

                break;
            case 'Change Camect Integration Type':
                const Changecamectintergrationtype = async () => {
                    const CamectIntegationType = camectIntegationType == 2 ? 1 : 2;
                    const notificationMessage = CamectIntegationType == 1 ? "Whole Camect" : "Per Cam Arming"
                    const response = await changeCamectIntegration(apiData, DeviceUUID, CamectIntegationType, DeviceName, dispatch)
                    if (response) {
                        setCamectIntegationType(CamectIntegationType)
                        dispatch(
                            uiActions.renderNotification({
                                status: 'successful',
                                title: tag || key,
                                message: "Camect integration changed to " + notificationMessage,
                            })
                        );
                        setOpenDropdownRowId(null);
                    }
                }
                Changecamectintergrationtype();
                break;
            case 'Copy site settings':
                const Copydevicesettings = async (apiData, fromDeviceUUID) => {
                    setModalTitle("Copy settings of: " + DeviceKey)
                    setModalComponent(
                        <div>
                            <InputForm label={"Enter site UIPIOID to copy settings to"} submitButtonText={"Copy"} processFunction={copyIPIOSettings} processFunctionParamaters={{ apiData, fromDeviceUUID }} HandleCloseModal={setOpenDropdownRowId} dispatch={dispatch} />
                        </div>
                    )
                    setOpenDropdownRowId(null);
                    HandleShowModal()
                }
                Copydevicesettings(apiData, DeviceUUID);
                break;
            case 'Reboot Site':
                const RebootDevice = async () => {
                    rebootIPIODevice(apiData, DeviceUUID, userID, DeviceName);
                    setOpenDropdownRowId(null);
                }
                RebootDevice();
                break;
            case 'Reset Site':
                const ResetDevice = async () => {
                    const response = resetIPIODevice(apiData, DeviceUUID, userID, DeviceName, dispatch)
                    if (response){
                        dispatch(
                            uiActions.renderNotification({
                                status: 'successful',
                                title: tag || key,
                                message: "Site reset successfully",
                            })
                        );
                    }
                    setOpenDropdownRowId(null);
                }
                ResetDevice();
            default:
        }
    }

    const favorited = cardData.favorited || 0;
    let isDeviceOnline;
    if (online === "YES") {
        isDeviceOnline = 1
    } else {
        isDeviceOnline = 0
    }

    const [toggleFavoritedSite, SetToggleFavoritedSite] = useState(favorited);
    const [toggleNotifications, setToggleNotifications] = useState();

    useEffect(() => {
        setToggleNotifications(singleDeviceSettingData[0]);
        SetToggleFavoritedSite(favorited);
    }, [cardData]); 

    const outputs =
    {
        output1: 1,
        output2: 0,
        output3: 0,
        output4: 1
    };

    const inputs =
    {
        input1: 1,
        input2: 1,
        input3: 1,
        input4: 1,
        input5: 0,
        input6: 1,
        input7: 1,
        input8: 0,
    };

    const ifSettingsCogIsClicked = openDropdownRowId === id
    const suspend = status == "suspended" ? "available" : "suspended";
    const showHiddenFeatures = userRole === "TechSupport";
    const showSerial = companyName === 'EyeSpyFX' || companyName === "RE:SURE"

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} modalName={"Site View"} HandleShowModal={HandleShowModal}>
                {modalComponent}
            </Modal>
            <div className={classes.GroupCardContentLayout}>
                <div className={classes["top-wrapper"]}>
                    <div className={classes["siteDetails-layout"]} style={{ opacity: online == "NO" && "50%" }}>
                        <h3 style={{ margin: "0px" }} className={`${classes.tagName} ${classes.ellipsis}`}>{updateTag || "No Tag"}</h3>

                        <div style={{ display: "flex" }}>
                            <h3 style={{ fontWeight: "400", margin: "0px" }} >{updateTag === "none" ? "" : key || "..."}</h3>
                            <h3 style={{ fontWeight: "500", marginLeft: "10px", marginTop: "0px", marginRight: "0px", marginBottom: "0px"}} >{"(v"}</h3>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{firmware || "..."}</h3>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{")"}</h3>
                        </div>

                        <div style={{display: "flex", height: "10px", opacity:"50%"}}>
                            <h3 style={{ fontWeight: "500", margin: "0px" }} >{subGroup || " "}</h3>
                        </div>
                
                    </div>

                    <div className={classes["icons-container"]}  style={{ opacity: "100%" }}>
                        {showHiddenFeatures && <DropdownList
                            item={id}
                            icon={SettingsCog}
                            onClick={handleSettingsClick}
                            ifSettingsCogIsClicked={ifSettingsCogIsClicked}
                            buttonRef={buttonRef}
                            ref={dropdownRef}
                            title={"Actions"}
                            className={classes.container}
                            onUserActionClick={(userAction) =>
                                UserActionHandler(
                                    userAction,
                                    suspend,
                                    id,
                                    uuid,
                                    userID,
                                    name,
                                    key,
                                    camectIntegationType
                                )
                            }
                            listItems={items}
                        />}
                       <br></br>
                            <div className={classes.userCount}>
                                {userCount}
                                <img className={classes.Icon} src={UserIcon} />
                            </div> 
                    </div>
                </div>

                <CustomLink className={classes.Link} to={`/Dashboard/SiteView/${uuid}`} onClick={HandleSaveSearchQuery} style={{ opacity: online == "NO" && "50%" }}>
                    <div className={classes["middle-wrapper"]}>
                        <div className={classes["indicatorsCards-container"]}>
                            {GroupNamesArray[0] == "EyeSpyFX" ? <CompanyIndicatorCard companyName={GroupNamesArray[0]} /> :
                                GroupNamesArray.map((groupName, index) => {
                                    if (groupName.trim() == 'RE:SURE'){
                                        groupName = 'RESURE'
                                    }
                                    return (
                                        <CompanyIndicatorCard companyName={groupName} />
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className={classes["bottom-wrapper"]} to={`/Dashboard/SiteView/${uuid}`} onClick={HandleSaveSearchQuery}>
                        <div>
                            <ul className={classes.outputs}>
                                <OutputPinCircle outputs={outputs} OutputPins={OutputPins} />
                            </ul>
                        </div>

                        <div className={classes["bottom-wrapper_divider"]} />
                        <div>
                            <ul className={classes.inputs}>
                                <InputPinCircle inputs={inputs} InputPins={InputPins} />
                            </ul>
                        </div>
                        <div className={classes.arrowButton} to={`/Dashboard/SiteView/${uuid}`} onClick={HandleSaveSearchQuery}>
                            {navigation.state != "loading" ? <img className={classes.Icon} src={arrowIcon} /> :
                                < Oval stroke="var(--Primary-Color)" height={27} width={27} speed={1.5} />
                            }
                        </div>
                    </div>
                </CustomLink >
            </div >
        </>
    )
}

export default SiteCardContentLayout;
