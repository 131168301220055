import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../../Modal/Modal';
import Button1 from '../../../Button/Button1'
import RoundedInput from "./RoundedInput"

import classes from "./ScheduledAction.module.css"
import SaveButtonclass from "../CreateRule/SelectOutputs.module.css";
const ScheduledAction = React.forwardRef(({ body, OutPutOptions, title, isReminder, nextPage, onSubmit, DeleteSchedule, showRemoveScheduleButton }, ref) => {
    const [firstPinText, setFirstPinText] = useState(OutPutOptions[0].pinName)

    useEffect(() => {
        const PinsPinNames = OutPutOptions.find(output => output.Pin == body.pin[0]);
        setFirstPinText(PinsPinNames.pinName)
    }, [body])

    let currentlySetTime;
    let currentlySetTimeArray;

    // spilt time into hours and minutes, to populate displayed time. For edit view
    if (body && body.time != "") {
        currentlySetTime = body.time;
        currentlySetTimeArray = currentlySetTime.split(":");
    }

    //variables
    const [daysSelected, setdaysSelected] = useState(body.days || []);
    const [isAutoArmed, setIsAutoArmed] = useState(body.action == "Disarm" ? false : true || false);
    const [isAutomationActive, setIsAutomationActive] = useState(body.active);
    const [hour, setHour] = useState(currentlySetTimeArray && currentlySetTimeArray[0] || '12');
    const [minutes, setMinutes] = useState(currentlySetTimeArray && currentlySetTimeArray[1] || "00");

    //edit state
    const [editTime, setEditTime] = useState(false)


    const HandelOnClick = () => {

        const actionString = HandlePrepareAction();

        const isActive = HandlePrepareIsActive();

        const time = HandlePrepareTime();


        const Body = { ...body, days: daysSelected, time: time, active: isActive, action: actionString, reminder: isReminder }
        onSubmit(Body);
    }

    const HandlePrepareTime = () => {
        return hour + ":" + minutes;
    }


    const HandlePrepareIsActive = () => {
        return + isAutomationActive; // returns 1 if isAutomationActive is true, and 0 if it's false, regardless of its initial type.
    }

    const HandlePrepareAction = () => {
        return isAutoArmed ? "Arm" : "Disarm";
    }

    const HandleResetAllStates = () => {
        setIsAutoArmed(true)

        setIsAutomationActive(false)
        setEditTime(false)
        setHour(currentlySetTimeArray[0]);
        setMinutes(currentlySetTimeArray[1]);
    }

    // Expose the method to the parent component using ref
    React.useImperativeHandle(ref, () => ({
        HandleResetAllStates: HandleResetAllStates
    }));

    const HandleOnDayClicked = (dayIndex) => {
        const isDayAlreayClicked = daysSelected.find(Day => Day == dayIndex);

        if (isDayAlreayClicked) {
            removeItem(dayIndex)
        } else {
            addItem(dayIndex)
        }
    }

    const addItem = (dayIndex) => {
        // Create a copy of the current array
        const newArray = [...daysSelected];
        // // Push the new item to the copy
        newArray.push(dayIndex);

        // // Set the state with the new array
        setdaysSelected(newArray);

    };

    const removeItem = (dayIndex) => {
        // Create a copy of the current array
        const newArray = [...daysSelected];

        const filterArray = newArray.filter(Day => Day !== dayIndex)

        // Set the state with the new array
        setdaysSelected(filterArray);
    }

    const Days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];


    // ---------------------------- Set Time Logic ------------------------------------------
    useEffect(() => {

        // if the hour input is left blank - repopulate it with previous value
        if (hour == "" || hour == null || hour == undefined) {
            setHour(currentlySetTimeArray[0]);
        }

        // if the Minutes input is left blank - repopulate it with previous value
        if (minutes == "" || minutes == null || minutes == undefined) {
            setMinutes(currentlySetTimeArray[0]);
            setMinutes(currentlySetTimeArray[1]);
        }

        const hourAsString = hour.toString(); // Convert number to string
        const hourAsStringLength = hourAsString.length;

        // make sure hour are not 1 but 01
        if (hourAsStringLength < 2) {
            const newHour = 0 + hour
            setHour(newHour);
        }

        const minutesAsString = minutes.toString(); // Convert number to string
        const minutesAsStringLength = minutesAsString.length;
        // make sure minutes are not 1 but 01
        if (minutesAsStringLength < 2) {
            const newHour = 0 + minutes
            setMinutes(newHour);
        }




    }, [editTime])


    const handleChangeHour = (event) => {
        if (event.target.value === "") {
            return
        }
        const newValue = event.target.value;
        if (IsHourValueValid(newValue) && IsHourValueValid(newValue)) {
            setHour(newValue)
        }
    };

    const handleChangeMinutes = (event) => {
        if (event.target.value === "") {
            return
        }
        const newValue = event.target.value;
        if (IsValueValid(newValue) && IsMinuteValueValid(newValue)) {
            setMinutes(newValue);
        }
    };

    const IsValueValid = (value) => {
        // check if value is int and the length is not more than 2 characters
        if ((/^\d*$/.test(value) && value.length <= 2)) {
            return true;
        } else {
            return false
        }
    }

    const IsHourValueValid = (value) => {
        // check if value is with 24 hour range
        if (value >= 0 && value <= 23) {
            return true;
        }
    }

    const IsMinuteValueValid = (value) => {
        // check if value is with 24 hour range
        if (value >= 0 && value < 60) {
            return true;
        } else {
            return false
        }
    }

    const getValueLength = (value) => {
        const numberAsString = value.toString(); // Convert number to string
        const numberLength = numberAsString.length;
        if (numberLength < 2) {
            return true
        } else {
            return false;
        }

    }
    //-------------------------------------------------------------------------------------------


    //if newValue < 10 0 + newValue || if newValue >


    //to change the background colour of the removebutton when hovering over it.
    const [onMouseEnterRemove, setOnMouseEnterRemove] = useState()
    
    return (
        <div>
            <div className={classes.verticalFlex}>
                <div className={classes.verticalFlex}>
                    <div className={classes.horizontalFlex} >
                        <Button1 style={{ minWidth: "110px", backgroundColor: isAutoArmed && "var(--Primary-Color)", color: isAutoArmed && "white" }} onClick={() => setIsAutoArmed(true)}>{isReminder == 1 ? "Armed" : "Auto Armed"}</Button1>
                        <Button1 style={{ minWidth: "110px", backgroundColor: !isAutoArmed && "var(--Primary-Color)", color: !isAutoArmed && "white" }} onClick={() => setIsAutoArmed(false)}>{isReminder == 1 ? "Disarmed" : "Auto Disarmed"}</Button1>
                    </div>

                    <div className={classes.verticalFlex}>
                        <h4 style={{ margin: "0px", fontWeight: "400", width: "fit-content" }} >at</h4>


                        {!editTime ? <h2 onClick={() => setEditTime((prevEditTime) => !prevEditTime)} style={{ margin: "5.8px", fontWeight: "400", cursor: "pointer" }}>{hour + ":" + minutes || "12:00"}</h2> :
                            <div className={classes.horizontalFlex} style={{ position: "relative" }}>
                                <RoundedInput value={hour} onChange={handleChangeHour} placeholder={hour || "12"} />
                                :
                                <RoundedInput value={minutes} onChange={handleChangeMinutes} placeholder={minutes || "00"} />
                                <div style={{ position: "absolute", left: "380px", bottom: "30px", textAlign: "center", borderRadius: "50%", border: "1px solid black", width: "20px", height: "fit-content", cursor: "pointer" }} onClick={() => setEditTime(false)} >X</div>
                            </div>
                        }
                    </div>

                </div >

                {/* <div className={classes.verticalFlex}>
                    <h4 style={{ margin: "0px", fontWeight: "400", textAlign: "center" }}>Repeat:</h4>
                    <ul className={classes.horizontalFlex}>
                        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (

                            <li key={index}>
                                <Button1 style={{ backgroundColor: daysSelected.find(Day => Day == day) && "var(--Primary-Color)", color: daysSelected.find(Day => Day == day) && "white" }} onClick={() => HandleOnDayClicked(day)}>{day}</Button1>
                            </li>
                        ))}
                    </ul>
                </div> */}



                <div className={classes.verticalFlex}>
                    <h4 style={{ margin: "0px", fontWeight: "400", textAlign: "center" }}>Repeat:</h4>
                    <ul className={classes.horizontalFlex}>
                        {["0", "1", "2", "3", "4", "5", "6"].map((dayIndex, index) => (

                            <li key={index}>
                                <Button1 style={{ backgroundColor: daysSelected.find(Day => Day == dayIndex) && "var(--Primary-Color)", color: daysSelected.find(Day => Day == dayIndex) && "white" }} onClick={() => HandleOnDayClicked(dayIndex)}>{Days[dayIndex]}</Button1>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={classes.verticalFlex} style={{ gap: "10px" }}>
                    <Button1 style={{ minWidth: "110px", backgroundColor: isAutomationActive && "var(--Primary-Color)", color: isAutomationActive && "white" }} onClick={() => setIsAutomationActive((prevIsAutomation) => !prevIsAutomation)} >Active</Button1>
                    <Button1 style={{ minWidth: "110px" }} onClick={() => nextPage(3)}>[{firstPinText}]</Button1>

                    <div style={{ display: "flex", gap: "20px" }}>
                        {showRemoveScheduleButton && <Button1 onMouseEnter={() => setOnMouseEnterRemove(true)} onMouseLeave={() => setOnMouseEnterRemove(false)} style={{ backgroundColor: onMouseEnterRemove && "red" }} onClick={DeleteSchedule}>{"Remove " + title}</Button1>}
                        <button style={{ minWidth: "192px" }} className={SaveButtonclass.SaveButton} onClick={HandelOnClick}>{"Save"}</button>
                    </div>

                </div>
            </div >
        </div>
    )
})

export default ScheduledAction